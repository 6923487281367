import {
    fetchBaseQuery,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
  } from "@reduxjs/toolkit/query/react";
import type { RootState } from "src/Redux/store";
import { msalInstance, loginRequest } from "src/auth/auth-config";
import { setToken } from "./authSlice";

const baseQuery = fetchBaseQuery({
    prepareHeaders: async (headers, { getState }) => {
      const token = (getState() as RootState)?.auth.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });
  
  
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const { accessToken } = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: msalInstance.getActiveAccount() || undefined,
    });
    if (accessToken) {
      // store the new token
      api.dispatch(setToken(accessToken));
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      msalInstance.logoutRedirect();
    }
  }
  return result;
};
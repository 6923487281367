import styled from "styled-components";
import { UploadResponse } from "./type";
import { ButtonGroup } from "@progress/kendo-react-buttons";
import Button from "../shared/Button/Button";
import { StyledSecondaryLink } from "./createRequest";
import { Typography } from "@progress/kendo-react-common";

const FileUploadSuccess = ({
  uploadStatus,
  onClose,
}: {
  uploadStatus: UploadResponse;
  onClose: () => void;
}) => {
  return (
    <>
      <StyledMessage>{uploadStatus?.message}</StyledMessage>
      <StyledP>Request No: {uploadStatus?.results[0]?.requestNumber}</StyledP>

      <ButtonGroup>
        <Button variant="primary" onClick={onClose}>
          Create Another Request
        </Button>

        <StyledSecondaryLink to="/">Back to Dashboard</StyledSecondaryLink>
      </ButtonGroup>
    </>
  );
};

export default FileUploadSuccess;

const StyledMessage = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const StyledP = styled(Typography.p)``;

import { FILE_NAME_PREPEND } from "src/utils/constants";
import { axiosInstance } from "./axiosConfig";
export const downloadFile = async (url: string, fileName: string) => {
  try {
    const res = await axiosInstance.get(url, { responseType: 'blob' });
    const downLoadedFile = await res.data;
    if (downLoadedFile) {
      const link = document.createElement("a");
      const downloadUrl = window.URL.createObjectURL(downLoadedFile);
      link.href = downloadUrl;
      link.download = `${FILE_NAME_PREPEND}${fileName.split(" ").join("_")}`;
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
    }
  } catch (error) {
    console.log(error);
  }
};

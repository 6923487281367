import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import App from "./App.tsx";
import store from "./Redux/store.ts";
import "./index.scss";
import { msalInstance } from "./auth/auth-config.tsx";

const sentryConfig = {
  dsn: import.meta.env.VITE_SENTRY_DSN_WEB,
  environment: import.meta.env.VITE_NODE_ENV,
  release: import.meta.env.VITE_GIT_COMMIT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

Sentry.init(sentryConfig);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App />
      </Provider>
    </MsalProvider>
  </React.StrictMode>
);

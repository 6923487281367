import Button from "components/shared/Button/Button";
import { Typography } from "@progress/kendo-react-common";
import { layout } from "src/styles/_breakpoints";
import { color } from "src/styles/_colors";
import { Heading24, Body16 } from "src/styles/_typography";
import styled from "styled-components";
import ErrorIcon from "src/images/error.svg";

interface UnAuthenticatedModalProps {
  handleLogin: () => void;
}

const UnAuthenticatedModal: React.FC<UnAuthenticatedModalProps> = ({
  handleLogin,
}) => {
  return (
    <UnAuthenticatedModalBody>
      <UnAuthenticatedContent>
        <img src={ErrorIcon} alt="error icon" />
        <UnAuthenticatedModalHeader>
          Oops! You're Not Logged In{" "}
        </UnAuthenticatedModalHeader>
        <UnAuthenticatedModalText>
          Please click the button below to continue.
        </UnAuthenticatedModalText>
      </UnAuthenticatedContent>
      <LineDivider />
      <UnAuthenticatedModalButton>
        <LogInButton onClick={handleLogin}>Log in</LogInButton>
      </UnAuthenticatedModalButton>
    </UnAuthenticatedModalBody>
  );
};

export default UnAuthenticatedModal;

const UnAuthenticatedModalBody = styled.div`
  width: 450px;
  @media ${layout.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
`;

const UnAuthenticatedContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
`;

const UnAuthenticatedModalHeader = styled(Typography.h2)`
  ${Heading24};
  margin-top: 16px;
`;

const UnAuthenticatedModalText = styled(Typography.p)`
  ${Body16};
  color: ${color.gray50};
  font-weight: 400;
`;

const UnAuthenticatedModalButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;

const LogInButton = styled(Button)`
  margin-top: 10px;
  @media ${layout.mobile} {
    width: 100%;
  }
`;

export const LineDivider = styled.div`
  width: 100%;
  border: 1px solid ${color.gray96};
`;

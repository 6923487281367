import { Component, ErrorInfo, ReactNode } from "react";
import ErrorFallbackPage from "./ErrorFallbackPage";
import { ERROR_TOAST_MESSAGE } from "src/constants/SharedConstant";

interface ErrorBoundaryProps {
  readonly children?: ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, errorMessage: "" };
    
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI
    return { hasError: true, errorMessage: ERROR_TOAST_MESSAGE };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public resetState() {
    this.setState({ hasError: false });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return <ErrorFallbackPage  message={this.state.errorMessage}/>;
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;

import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RItemResponse, WhItemResponse } from "src/interfaces/types";

export interface UpdateRequestState {
  whItems: WhItemResponse[];
  rItems: RItemResponse[];
}

const initialState: UpdateRequestState = {
  whItems: [],
  rItems: [],
};

export const updateRequestSlice = createSlice({
  name: "updateRequest",
  initialState,
  reducers: {
    addWhItem: (state, action: PayloadAction<WhItemResponse>) => {
      state.whItems.push(action.payload); // Add the new whitem to the array
    },
    addRItem: (state, action: PayloadAction<RItemResponse>) => {
      state.rItems.push(action.payload); // Add the new ritem to the array
    },
    removeWhItem: (state, action: PayloadAction<string>) => {
      state.whItems = state.whItems.filter(
        (item) => item.whitem !== action.payload
      );
    },
    removeRItem: (state, action: PayloadAction<string>) => {
      state.rItems = state.rItems.filter(
        (item) => item.rItem !== action.payload
      );
    },
  },
});

export const { addWhItem, addRItem, removeWhItem, removeRItem } =
  updateRequestSlice.actions;
export default updateRequestSlice.reducer;

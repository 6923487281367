import { useMemo } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import classNames from "classnames";
import TableColCell from "../TableColCell";
import { ColumnField } from "../type";
import { HeaderCell } from "../HeaderCell";

interface UseColumnsProps {
  reqFields?: ColumnField[];
}

export const useTableColumns = ({ reqFields }: UseColumnsProps) => {
  return useMemo(() => {
    return reqFields?.map((field) => {
      const headerClass = classNames("custom-header", {
        "editable-header": field.editable,
      });

      const tdClass = classNames({
        editable: field.editable,
      });
      return (
        <GridColumn
          key={field.headerName}
          cell={(props) => (
            <TableColCell
              {...props}
              inEdit={props.dataItem.inEdit}
              isCustomFields={field?.customFields}
              isEditable={field.editable || false}
            />
          )}
          field={field.field}
          title={field.headerName}
          width={field.width || "auto"}
          editable={field.editable || false}
          locked={field.locked || false}
          resizable={!field.locked}
          headerClassName={headerClass}
          className={tdClass}
          headerCell={HeaderCell}
        />
      );
    });
  }, [reqFields]);
};

import styled from "styled-components";

import { Loader, LoaderProps } from "@progress/kendo-react-indicators";

export const LoaderSection = ({className, ...props}:LoaderProps) => {
  return (
    <LoaderDiv {...{className}}>
      <Loader size="large" type="infinite-spinner" {...props} />
    </LoaderDiv>
  );
};

const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

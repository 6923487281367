import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: import.meta.env.VITE_B2C_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${import.meta.env.VITE_B2C_TENANT}`,
        redirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

export const loginRequest = {
    scopes: [`api://${import.meta.env.VITE_B2C_CLIENT_ID}/gesp`,"User.Read"]
};

export const msalInstance = new PublicClientApplication(msalConfig);
import { GridCellProps } from "@progress/kendo-react-grid";
import { parseDate } from "src/utils/dataUtils";
import { FieldTypeProps } from "./type";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import SelectDropdown from "../selectDropdown/selectDropdown";
import {
  NumericTextBox,
  NumericTextBoxChangeEvent,
} from "@progress/kendo-react-inputs";
interface TableColCellProps extends GridCellProps {
  isCustomFields?: FieldTypeProps;
  inEdit?: boolean;
  isEditable: boolean;
}

const TableColCell = ({
  className,
  isCustomFields,
  inEdit,
  isEditable,
  ...props
}: TableColCellProps) => {
  const value = (props.field && props.dataItem[props.field]) || "";
  const customFields = isCustomFields;
  const title = customFields?.fieldType === "date" ? "" : value;

  return (
    <td title={title} style={props.style} className={className}>
      {renderComponent(props, value, customFields, inEdit && isEditable)}
    </td>
  );
};

const handleOnChange = (
  e:
    | DropDownListChangeEvent
    | NumericTextBoxChangeEvent
    | React.ChangeEvent<HTMLInputElement>,
  props: GridCellProps,
  value: string | number | null
) => {
  const syntheticEvent = "syntheticEvent" in e ? e.syntheticEvent : e;
  props.onChange &&
    props.onChange({
      dataIndex: 0,
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent,
      value,
    });
};

const renderComponent = (
  props: GridCellProps,
  value: string,
  customFields?: FieldTypeProps,
  isEditable?: boolean
) => {
  const fieldType = customFields?.fieldType;

  switch (fieldType) {
    case "dropdown":
      if (isEditable) {
        return (
          <SelectDropdown
            code={customFields?.code}
            defaultValue={{ name: value }}
            onChange={(e) => handleOnChange(e, props, e.target.value?.name)}
          />
        );
      }
      return value;

    case "date": {
      return parseDate(value);
    }
    case "numeric": {
      if (isEditable) {
        return (
          <NumericTextBox
            placeholder="please enter value"
            defaultValue={77}
            format="n2"
            onChange={(e) => handleOnChange(e, props, e.target.value)}
          />
        );
      }
      return value;
    }
    default: {
      if (isEditable) {
        return (
          <input
            className="k-input"
            data-prevent-selection="true"
            value={value}
            onChange={(e) => handleOnChange(e, props, e.target.value)}
          />
        );
      }
      return value;
    }
  }
};

export default TableColCell;

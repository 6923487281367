import Dashboard from "components/dashboard/dashboard";
import { CreateRequest } from "components/create-request/createRequest";
import { UpdateRequest } from "components/update-request/updateRequest";

export const NAVIGATION_ITEMS = [
    {
      label: "Dashboard",
      path: "/",
      component: <Dashboard />,
    },
    {
      label: "New Item Request",
      path: "/create",
      component: <CreateRequest />,
    },
    {
      label: "Update Item",
      path: "/update",
      component: <UpdateRequest />,
      disabled: import.meta.env.VITE_NODE_ENV !== "development",
    },
  ];
  
  export const ROUTES = [...NAVIGATION_ITEMS];
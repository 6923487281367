import { Typography } from "@progress/kendo-react-common";
import React from "react";
import { layout } from "src/styles/_breakpoints";
import { color } from "src/styles/_colors";
import { Heading18, Body14, BodyBold14 } from "src/styles/_typography";
import styled from "styled-components";

const Instructions: React.FC = () => {
  return (
    <InstructionsWrapper>
      <InstructionContainer>
        <Instruction>
          <StepContainer>
            <StyledH2>Step 1: Select the Item Type</StyledH2>
            <StyledContent>
              <Typography.p>
                Choose the appropriate Item Type for the new item(s) you are
                setting up:
              </Typography.p>
              <StyledUl>
                <li>
                  <BoldText>DSD Item:</BoldText> For retail products that
                  suppliers ship directly to stores (Direct Store Delivery).
                </li>
                <li>
                  <BoldText>DSD Shipper:</BoldText> For in-store displays that
                  contain multiple retail items and are shipped directly to
                  stores.
                </li>
                <li>
                  <BoldText>Warehouse Item:</BoldText> For retail products that
                  vendors ship to a GE warehouse before being redistributed to
                  stores.
                </li>
                <li>
                  <BoldText>Warehouse Shipper:</BoldText> For in-store displays
                  that contain multiple retail items and are shipped to a GE
                  warehouse.
                </li>
                <li>
                  <BoldText>Crossdock / Specialty PTS:</BoldText> For items
                  ordered by Supply Chain Order Management (GESCOM) based on
                  store orders.
                </li>
              </StyledUl>
            </StyledContent>
          </StepContainer>
          <StepContainer>
            <StyledH2>Step 2: Download the Form</StyledH2>
            <StyledContent>
              <Typography.p>
                Click the <BoldText>'Spreadsheet Download'</BoldText> button to
                receive the latest version of the New Item Form for the selected
                item type.
              </Typography.p>
            </StyledContent>
          </StepContainer>
        </Instruction>
        <VerticalLine />
        <Instruction>
          <StepContainer>
            <StyledH2>Step 3: Complete the New Item Form</StyledH2>
            <StyledContent>
              <StyledOl>
                <li>
                  Open the New Item Form and refer to the{" "}
                  <BoldText>'Instructions'</BoldText> tab for detailed guidance.
                </li>
                <li>
                  Follow all formatting and data standards as described in the
                  field headers.
                </li>
                <li>
                  Ensure that all mandatory fields (marked with a red asterisk)
                  are filled out.
                </li>
              </StyledOl>
            </StyledContent>
          </StepContainer>
          <StepContainer>
            <StyledH2>Step 4: Upload the Completed Form</StyledH2>
            <StyledContent>
              <StyledOl>
                <li>
                  When you've completed the form, press the{" "}
                  <BoldText>'Select Files'</BoldText>
                  button.
                </li>
                <li>Select the completed New Item Form to upload:</li>
                <StyledUl>
                  <li>
                    The file must be in <BoldText>.XLSX</BoldText> format.
                  </li>
                  <li>
                    Ensure you are using the{" "}
                    <BoldText>latest version of the New Item Form</BoldText>{" "}
                    (validation will fail otherwise).
                  </li>
                </StyledUl>
              </StyledOl>
            </StyledContent>
          </StepContainer>
          <StepContainer>
            <StyledH2>Step 5: Validate the Form</StyledH2>
            <StyledContent>
              <StyledUl>
                <li>
                  The system will automatically validate the uploaded form.
                </li>
                <li>
                  If there are any <BoldText>errors,</BoldText> correct them and
                  upload the form again.
                </li>
              </StyledUl>
            </StyledContent>
          </StepContainer>
        </Instruction>
      </InstructionContainer>
    </InstructionsWrapper>
  );
};

export default Instructions;

const InstructionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px 0;
`;
const VerticalLine = styled.div`
  width: 1px;
  flex-shrink: 0;
  background-color: ${color.gray90};
  height: 85%;
  margin: auto 0;
  @media ${layout.tabletS}, ${layout.mobile} {
    display: none;
  }
`;
const InstructionContainer = styled.div`
  display: flex;
  margin: 0 48px;
  flex-grow: 1;
  flex-basis: 0;
  background-color: ${color.white};
  border-radius: 14px;
  max-width: 1344px;
  @media ${layout.tabletS}, ${layout.mobile} {
    flex-direction: column;
    padding: 10px;
    margin: 0;
  }
`;

const Instruction = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
  flex-basis: 100%;
  @media ${layout.tabletS}, ${layout.mobile} {
    padding: 0px 16px;
  }
`;

const StyledH2 = styled(Typography.h2)`
  ${Heading18}
  margin-bottom: 8px;
`;
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const StyledContent = styled.div`
  ${Body14}
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  color: ${color.gray30};
  font-weight: 400;
`;

const StyledUl = styled.ul`
  padding-inline-start: 20px;
  margin-top: 0px;
  list-style-type: disc;
`;

const StyledOl = styled.ol`
  padding-inline-start: 20px;
  margin-top: 0px;
`;

const BoldText = styled.span`
  ${BodyBold14}
`;

import { ButtonHTMLAttributes, forwardRef } from "react";
import styled, { css } from "styled-components";
import { color } from "src/styles/_colors";
import { SVGIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";

export type ButtonVariant = "primary" | "secondary";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  disabled?: boolean;
  icon?: SVGIcon | string;
  children?: React.ReactNode;
  $rounded?: boolean;
}

export const BaseStyles = css`
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  border-radius: 80px;
  align-items: center;
  display: flex;
  font-weight: 700;
  justify-content: center;
  white-space: nowrap;
  border: none;
  gap: 8px;
  > .k-icon {
    margin-right: 5px;
  }
  > .icon {
    pointer-events: none;
  }
  &:active,
  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }

  &:focus {
    border: 2px dashed ${color.gray30};
  }
`;

const PrimaryStyles = css`
  background: ${color.primaryGradient};
  color: ${color.white};

  &:active,
  &:hover {
    background: ${color.primary};
  }

  &:disabled {
    background: ${color.gray96};
  }
`;

export const SecondaryStyles = css`
  background: ${color.white};
  border: 1px solid ${color.gray90};
  color: ${color.gray30};
  &:hover {
    background: ${color.gray100};
  }
  &:active {
    background: ${color.gray96};
  }
  &:disabled {
    background: ${color.white};
    color: ${color.gray90};
  }
`;
export const RoundedStyles = css`
  border-radius: 50%;
  padding: 12px;
`;

const StyledButton = styled.button<ButtonProps>`
  ${BaseStyles}
  ${({ variant }) => variant === "primary" && PrimaryStyles}
  ${({ variant }) => variant === "secondary" && SecondaryStyles}
  ${({ $rounded }) => $rounded && RoundedStyles}
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = "primary",
      disabled = false,
      icon,
      children,
      $rounded,
      ...props
    }: ButtonProps,
    ref
  ) => (
    <StyledButton
      ref={ref}
      variant={variant}
      $rounded={$rounded}
      disabled={disabled}
      {...props}
    >
      {icon && typeof icon === "string" && (
        <img className="icon" src={icon} alt="icon" />
      )}
      {icon && typeof icon !== "string" && <SvgIcon icon={icon} />}
      {children}
    </StyledButton>
  )
);

export default Button;

import { Dialog } from "@progress/kendo-react-dialogs";
import styled from "styled-components";
import { DialogBoxProps } from "./type";

const DialogBox = ({ isOpen = false, children, ...props }: DialogBoxProps) => {
  return (
    <>
      {isOpen && (
        <StyledDialog {...props}>
          <DialogContainer>{children}</DialogContainer>
        </StyledDialog>
      )}
    </>
  );
};

export default DialogBox;

const DialogContainer = styled.div`
  text-align: left;
`;

const StyledDialog = styled(Dialog)`
  div {
    border-radius: 15px;
  }
`;

// errorContext.ts
import { ReactNode, createContext, useMemo, useState } from "react";
import { ErrorContextType } from "./ErrorContext";

export const ErrorContext = createContext<ErrorContextType>({
  errorMessage: "",
  setErrorMessage: () => {},
});

interface Props {
  readonly children: ReactNode;
}

function ErrorContextProvider({ children }: Props) {
  const [errorMessage, setErrorMessage] = useState("");

  const value = useMemo(
    () => ({
      errorMessage,
      setErrorMessage,
    }),
    [errorMessage]
  );

  return (
    <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>
  );
}

export { ErrorContextProvider };
import { createApi } from "@reduxjs/toolkit/query/react";
import { IND_API_DOMAIN, OP_API_DOMAIN } from "src/utils/constants";
import { ApiEndpoints } from "src/utils/constants";
import { ItemResponse } from "src/interfaces/types";
import { GetCodesResults } from "src/components/shared/selectDropdown/types";
import { UploadResponse } from "src/components/create-request/type";
import { baseQueryWithReauth } from "./baseQueryWithReauth";

export const querySlice = createApi({
  reducerPath: "querySlice",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    fetchItemRequests: builder.query<ItemResponse[], void>({
      query: () => ({ url: `${IND_API_DOMAIN}${ApiEndpoints.dashboard}` }),
    }),

    uploadFile: builder.mutation<UploadResponse, FormData>({
      query: (formData) => {
        return {
          url: `${IND_API_DOMAIN}${ApiEndpoints.upload}`,
          method: "POST",
          body: formData,
        };
      },
    }),
    getCode: builder.query<GetCodesResults, number>({
      query: (id) => ({ url: `${IND_API_DOMAIN}${ApiEndpoints.code(id)}` }),
    }),
    getWarehouseItems: builder.query({
      query: (params) => ({
        url: `${OP_API_DOMAIN}${ApiEndpoints.getWarehouseItems}`,
        method: "GET",
        params,
      }),
    }),
    getRetailItems: builder.query({
      query: (params) => ({
        url: `${OP_API_DOMAIN}${ApiEndpoints.getRetailItems}`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useFetchItemRequestsQuery,
  useGetCodeQuery,
  useUploadFileMutation,
  useLazyGetWarehouseItemsQuery,
  useLazyGetRetailItemsQuery,
} = querySlice;

import { useEffect, useState } from "react";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { useErrorContext } from "src/contexts/ErrorContext";

const ErrorFallbackPage = ({ message }: Record<string,string>) => {
  const [shouldShow, setShouldShow] = useState(false);
  const { errorMessage } = useErrorContext();

  useEffect(() => {
    // 1 second delay to prevent temporary error flash being shown
    const timeout = setTimeout(() => {
      setShouldShow(true);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  return shouldShow ? (
    <NotificationGroup
      style={{
        position: "fixed",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        alignItems: "flex-start",
        flexWrap: "wrap-reverse",
      }}
    >
      <Fade>
        {
          <Notification type={{ style: "error", icon: true }}>
            <span>{errorMessage || message}</span>
          </Notification>
        }
      </Fade>
    </NotificationGroup>
  ) : null;
};

export default ErrorFallbackPage;

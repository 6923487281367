import { GridHeaderCellProps } from "@progress/kendo-react-grid";

export const HeaderCell = (props: GridHeaderCellProps) => {
  return (
    <a className="k-link" onClick={props.onClick}>
      <span title={props.title}>{props.title}</span>
      {props.children}
    </a>
  );
};

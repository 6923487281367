import { css } from "styled-components";
import { color } from "./_colors";
import { layout } from "./_breakpoints";

export const lineClamp = (lines: number, lineHeight: number = 1.2) => css`
  /* autoprefixer: off */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
  /* stylelint-disable-next-line function-calc-no-invalid */
  max-height: calc(${lineHeight}rem * ${lines});
`;

export const AVANT_GARDE_FONT = "AvantGardeGothicITC";

export const BoldBaseFontStyles = css`
  font-style: normal;
  font-weight: bold;
`;

export const NormalBaseFontStyles = css`
  font-style: normal;
  font-weight: normal;
`;

// Display text styles

export const Display40 = css`
  ${BoldBaseFontStyles}
  font-family: ${AVANT_GARDE_FONT};
  font-size: 40px;
  line-height: 48px;
`;

export const Display32 = css`
  ${BoldBaseFontStyles}
  font-family: ${AVANT_GARDE_FONT};
  font-size: 32px;
  line-height: 40px;
`;

// Header text styles

export const Heading24 = css`
  ${BoldBaseFontStyles}
  font-family: ${AVANT_GARDE_FONT};
  font-size: 24px;
  line-height: 32px;
`;

export const Heading20 = css`
  ${BoldBaseFontStyles}
  font-family: ${AVANT_GARDE_FONT};
  font-size: 20px;
  line-height: 26px;
`;

export const Heading18 = css`
  ${BoldBaseFontStyles}
  font-family: ${AVANT_GARDE_FONT};
  font-size: 18px;
  line-height: 22px;
`;

export const Heading16 = css`
  ${BoldBaseFontStyles}
  font-family: ${AVANT_GARDE_FONT};
  font-size: 16px;
  line-height: 20px;
`;

export const Heading14 = css`
  ${BoldBaseFontStyles}
  font-family: ${AVANT_GARDE_FONT};
  font-size: 14px;
  line-height: 16px;
`;

// Body text styles

export const Body18 = css`
  ${NormalBaseFontStyles}
  font-size: 18px;
  line-height: 22px;
`;

export const BodyBold18 = css`
  ${BoldBaseFontStyles}
  font-size: 18px;
  line-height: 22px;
`;

export const Body16 = css`
  ${NormalBaseFontStyles}
  font-size: 16px;
  line-height: 24px;
`;

export const BodyBold16 = css`
  ${BoldBaseFontStyles}
  font-size: 16px;
  line-height: 24px;
`;

export const Body14 = css`
  ${NormalBaseFontStyles}
  font-size: 14px;
  line-height: 20px;
`;

export const BodyBold14 = css`
  ${BoldBaseFontStyles}
  font-size: 14px;
  line-height: 20px;
`;

export const Body12 = css`
  ${NormalBaseFontStyles}
  font-size: 12px;
  line-height: 16px;
`;

export const BodyBold12 = css`
  ${BoldBaseFontStyles}
  font-size: 12px;
  line-height: 16px;
`;

// Other text styles

export const Mininav12 = css`
  ${BoldBaseFontStyles}
  font-family: ${AVANT_GARDE_FONT};
  font-size: 12px;
  line-height: 16px;
`;

export const Caption10 = css`
  ${NormalBaseFontStyles}
  font-size: 10px;
  line-height: 12px;
`;

export const CaptionBold10 = css`
  ${BoldBaseFontStyles}
  font-size: 10px;
  line-height: 12px;
`;

export interface MarkdownStyleOverrides {
  color?: string;
}

/**
 * Markdown Styles
 */
export const MarkdownStyles = (overrides?: MarkdownStyleOverrides) => css`
  h1 {
    ${Display40}
    margin-bottom: 24px;
    color: ${overrides?.color ?? color.gray30};

    @media ${layout.tablet} {
      ${Display32}
      margin-bottom: 16px;
    }

    @media ${layout.mobile} {
      ${Heading24}
      margin-bottom: 12px;
    }
  }

  h2 {
    ${Display32}
    margin-bottom: 24px;
    color: ${overrides?.color ?? color.gray30};

    @media ${layout.tablet} {
      ${Heading24}
      margin-bottom: 16px;
    }

    @media ${layout.mobile} {
      ${Heading20}
      margin-bottom: 12px;
    }
  }

  h3 {
    ${Heading24}
    margin-bottom: 24px;
    color: ${overrides?.color ?? color.gray30};

    @media ${layout.tablet} {
      ${Heading20}
      margin-bottom: 16px;
    }

    @media ${layout.mobile} {
      ${Heading18}
      margin-bottom: 12px;
    }
  }

  h4 {
    ${Heading20}
    margin-bottom: 16px;
    color: ${overrides?.color ?? color.gray30};

    @media ${layout.tablet} {
      ${Heading18}
      margin-bottom: 12px;
    }

    @media ${layout.mobile} {
      ${Heading14}
      margin-bottom: 8px;
    }
  }

  h5 {
    ${Heading20}
    margin-bottom: 16px;
    color: ${overrides?.color ?? color.gray30};

    @media ${layout.tablet} {
      ${Heading18}
      margin-bottom: 12px;
    }

    @media ${layout.mobile} {
      ${Heading14}
      margin-bottom: 8px;
    }
  }

  h6 {
    ${Heading14}
    margin-bottom: 16px;
    color: ${overrides?.color ?? color.gray30};

    @media ${layout.tablet} {
      margin-bottom: 12px;
    }

    @media ${layout.mobile} {
      margin-bottom: 8px;
    }
  }

  li {
    ${Body16}

    @media ${layout.tablet}, ${layout.mobile} {
      ${Body14}
    }
  }

  li p {
    margin-bottom: 0;
  }

  p {
    ${Body16}
    margin-bottom: 32px;
    color: ${overrides?.color ?? color.gray30};

    @media ${layout.tablet}, ${layout.mobile} {
      ${Body14}
      margin-bottom: 24px;
    }

    strong {
      ${BodyBold16}
      @media ${layout.tablet}, ${layout.mobile} {
        ${BodyBold14}
      }
    }

    em {
      font-style: italic;
    }
  }

  a:not([data-link-id="cta-styled"]) {
    color: ${color.gray30};
    text-decoration: underline;
  }

  ul {
    ${Body16};
    color: ${color.gray30};
    margin-left: 32px;
    list-style: disc;

    @media ${layout.tablet}, ${layout.mobile} {
      ${Body14};
      margin-left: 24px;
    }
  }

  ol {
    ${Body16};
    color: ${color.gray30};
    margin-left: 32px;
    list-style: decimal;

    @media ${layout.tablet}, ${layout.mobile} {
      ${Body14};
      margin-left: 24px;
    }
  }

  li {
    word-wrap: break-word;
  }
`;

export const HeaderStyles = css`
  ${Heading24};

  @media ${layout.tabletL} {
    ${Heading20};
  }
  @media ${layout.tabletS}, ${layout.mobile} {
    ${Heading18};
  }
`;

import { ChangeEventHandler, ReactNode } from "react";
import styled, { css } from "styled-components";

import { color } from "src/styles/_colors";
import { Body14, BodyBold14 } from "src/styles/_typography";

interface Props {
  id: string;
  groupName: string;
  label: string | ReactNode;
  value: string | number;
  isSelected: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
}

const RadioOption = ({
  groupName,
  id,
  value,
  label,
  isSelected,
  handleChange,
  disabled,
  hasError = false,
  className,
}: Props) => (
  <RadioOptionContainer className={className}>
    <RadioOptionLabel $isSelected={isSelected} htmlFor={id}>
      <RadioOptionInput
        type="radio"
        name={groupName}
        id={id}
        disabled={disabled}
        value={value}
        onChange={handleChange}
        checked={isSelected}
        data-test-id={id}
        $hasError={hasError}
      />
      {label}
    </RadioOptionLabel>
  </RadioOptionContainer>
);

const RadioOptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const RadioOptionLabel = styled.label<{
  $isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  ${Body14};

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      ${BodyBold14};
    `};
`;

const RadioOptionInput = styled.input<{ $hasError: boolean }>`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: 2px 8px 2px 2px;
  padding: 0;
  border: 1px solid ${color.gray75};
  border-radius: 44px;
  appearance: none;
  background-color: ${color.white};
  outline: none;
  cursor: pointer;
  aspect-ratio: 1 / 1;

  &:checked {
    background-clip: content-box;
    padding: 3px;
    background-color: ${color.green};
    border: 1px solid ${color.green};

    :disabled {
      cursor: not-allowed;
      border: 1px solid ${color.gray96};
      background-color: ${color.gray96};
    }

    :disabled + ${RadioOptionLabel} {
      cursor: not-allowed;
      color: ${color.gray96};
    }
  }

  :hover {
    border: 1px solid ${color.gray30};
  }

  :disabled {
    cursor: not-allowed;
    border: 1px solid ${color.gray96};
  }

  :disabled + ${RadioOptionLabel} {
    cursor: not-allowed;
    color: ${color.gray96};
  }

  :focus-visible {
    outline: 1px dashed ${color.gray30};
    border: 1px solid ${color.gray30};
    outline-offset: 2px;
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: ${color.primaryOn};
      + ${RadioOptionLabel} {
        color: ${color.primary};
      }

      :hover {
        border: 1px solid ${color.primary};
      }

      :checked {
        background-clip: content-box;
        padding: 3px;
        border: 1px solid ${color.primaryOn};
        background-color: ${color.primaryOn};

        :hover {
          border: 1px solid ${color.gray30};
          background-color: ${color.primary};
        }

        :focus-visible {
          outline: 1px dashed ${color.gray30};
          border: 1px solid ${color.gray30};
          outline-offset: 2px;
          background-color: ${color.primary};
        }
      }
    `};
`;

export default RadioOption;

import axios from "axios";
import { IND_API_DOMAIN } from "./constants";
import store from "src/Redux/store";
import { loginRequest, msalInstance } from "src/auth/auth-config";

export const axiosInstance = axios.create({
  baseURL: `${IND_API_DOMAIN}`,
});

const getAccessToken = (): string | null => {
  const state = store.getState();
  return state.auth.token;
};

axiosInstance.interceptors.request.use(
  (request) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      request.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: msalInstance.getActiveAccount() || undefined,
        });
        const { accessToken } = response;
        localStorage.setItem("accessToken", accessToken);
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${accessToken}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem("accessToken");
        msalInstance.logoutRedirect();
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

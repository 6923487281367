import { useState, useMemo, useEffect, useRef } from "react";
import {
  Grid,
  GridToolbar,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import styled from "styled-components";
import { useErrorContext } from "src/contexts/ErrorContext";
import { ERROR_TOAST_MESSAGE } from "src/constants/SharedConstant";
import { Typography } from "@progress/kendo-react-common";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { PageState } from "src/interfaces/types";
import { useTableColumns } from "./hooks/useTableColumns";
import { LoaderSection } from "src/components/loader/loader";
import { TableProps } from "./type";
import Button from "../Button/Button";
import ExportIcon from "src/images/exportIcon.svg";
import UndoIcon from "src/images/undoIcon.svg";
import DialogBox from "src/components/dialogBox/dialogBox";
import ConfirmModal from "src/components/confirmModal/confirmModal";

const EDIT_FIELD = "inEdit";

const Table = ({
  data,
  isLoading,
  error,
  reqFields,
  initialPageState,
  initialPageSort,
  exportFileName,
  tableHeight,
  onRowClick,
  onItemChange,
  updatedData,
  onUndo = undefined,
}: TableProps) => {
  const [page, setPage] = useState<PageState>(initialPageState);
  const [sort, setSort] = useState<SortDescriptor[]>(initialPageSort);
  const { setErrorMessage } = useErrorContext();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (error) {
      setErrorMessage(ERROR_TOAST_MESSAGE);
      throw error;
    }
  }, [error, setErrorMessage]);

  const results = useMemo(() => {
    return updatedData || data?.results || [];
  }, [data?.results, updatedData]);

  const columns = useTableColumns({ reqFields });
  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      try {
        _export.current.save();
      } catch {
        setErrorMessage(ERROR_TOAST_MESSAGE);
        throw error;
      }
    }
  };

  const undoChange = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmPress = () => {
    setIsDialogOpen(false);
    if (onUndo) {
      onUndo();
    }
  };

  const pageChange = (event: GridPageChangeEvent) => {
    const take = event.page.take;
    setPage({ ...event.page, take });
  };

  const orderData = () => {
    return orderBy(results, sort);
  };

  if (isLoading) return <LoaderSection />;

  if (error) return <StyledP>Error: {error}</StyledP>;

  return (
    <>
      {results.length > 0 && (
        <Tooltip openDelay={100} position="top" anchorElement="target">
          <ExcelExport
            ref={_export}
            data={orderData()}
            fileName={exportFileName}
          >
            <Grid
              style={{
                height: tableHeight,
                margin: "auto",
                maxWidth: "90%",
                border: 0,
              }}
              data={orderData()}
              skip={page.skip}
              take={page.take}
              total={results.length}
              pageable={{
                buttonCount: 4,
                type: "input",
                pageSizes: [5, 50, 100],
              }}
              onPageChange={pageChange}
              rowHeight={50}
              editField={EDIT_FIELD}
              resizable={true}
              navigatable={true}
              sortable={{ mode: "multiple", allowUnsort: true }}
              sort={sort}
              onSortChange={(e) => setSort(e.sort)}
              onRowClick={onRowClick}
              onItemChange={onItemChange}
            >
              <GridToolbar className="k-align-items-flex-end">
                {onUndo !== undefined && (
                  <StyledButton
                    variant="secondary"
                    title="Undo"
                    type="button"
                    onClick={undoChange}
                    icon={UndoIcon}
                  >
                    Undo
                  </StyledButton>
                )}
                <StyledButton
                  variant="secondary"
                  title="Export Excel"
                  type="button"
                  onClick={excelExport}
                  icon={ExportIcon}
                >
                  Export
                </StyledButton>
              </GridToolbar>
              {columns}
            </Grid>
          </ExcelExport>
        </Tooltip>
      )}
      <DialogBox isOpen={isDialogOpen} onClose={handleCloseDialog}>
        <ConfirmModal
          headerText="Are you sure you want to Undo?"
          handleClose={handleCloseDialog}
          handleConfirm={handleConfirmPress}
        />
      </DialogBox>
    </>
  );
};

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
`;

const StyledP = styled(Typography.p)``;
export default Table;

import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import {
  AppBarSection,
  AppBarSpacer,
  AppBar,
} from "@progress/kendo-react-layout";
import supplierPortalLogo from "./images/supplierPortalLogo.svg";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import { ErrorContextProvider } from "./contexts/ErrorContextProvider";
import styled from "styled-components";
import { layout } from "./styles/_breakpoints";
import { useRef, useState, useEffect } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { menuIcon } from "@progress/kendo-svg-icons";
import AccountIcon from "src/images/AccountIcon.svg";
import UnionIcon from "src/images/UnionIcon.svg";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./auth/auth-config";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "./Redux/Slice/Api/authSlice";
import { RootState } from "./Redux/store";
import Button from "./components/shared/Button/Button";
import { color } from "./styles/_colors";
import DialogBox from "./components/dialogBox/dialogBox";
import UnAuthenticatedModal from "./components/unauthenticated-modal/unAuthenticatedModal";
import { NAVIGATION_ITEMS, ROUTES } from "src/utils/navigation";
import { Tooltip } from "@progress/kendo-react-tooltip";
import ConfirmModal from "./components/confirmModal/confirmModal";

function App() {
  const [userName, setUserName] = useState<string>("");

  const { accounts, instance: msalInstance, inProgress } = useMsal();

  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const isTokenAvailable = useSelector((state: RootState) =>
    Boolean(state.auth.token)
  );
  const [showOptions, setShowOptions] = useState(false);
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const anchor = useRef<HTMLDivElement>(null);
  const handleLogout = () => {
    setLogoutConfirm(true);
  };
  const handleConfirmLogout = () => {
    dispatch(setToken(""));
    msalInstance.logout();
  };
  const handleCloseLogout = () => {
    setLogoutConfirm(false);
  };
  useEffect(() => {
    if (!accounts || inProgress !== InteractionStatus.None) return;
    const accesTokenRequest = {
      ...loginRequest,
      account: accounts[0],
    };
    const getSilentToken = async () => {
      try {
        const { accessToken } = await msalInstance.acquireTokenSilent(
          accesTokenRequest
        );
        dispatch(setToken(accessToken));
      } catch (e) {
        console.error(e);
        msalInstance.loginRedirect(loginRequest);
      }
    };
    if (isAuthenticated) {
      const { username, name } = accounts[0];
      setUserName(name ?? username);
      getSilentToken();
    }
  }, [
    msalInstance,
    isAuthenticated,
    accounts,
    accounts.length,
    inProgress,
    dispatch,
  ]);
  return (
    <>
      <AuthenticatedTemplate>
        <Router>
          <Container
            onClick={() => {
              if (showOptions) {
                setShowOptions(false);
              }
            }}
          >
            <Nav aria-label="Navigation bar" style={{}}>
              <LogoWrapper>
                <Logo src={supplierPortalLogo} alt="GE Supplier Portal Logo" />
                <DesktopOptions>
                  {NAVIGATION_ITEMS.filter((item) => !item.disabled).map(
                    (item) => (
                      <AppBarSection key={item.path}>
                        <StyledLink to={item.path}>{item.label}</StyledLink>
                      </AppBarSection>
                    )
                  )}
                </DesktopOptions>
              </LogoWrapper>
              <StyledAppBarSpacer />
              <DesktopOptions>
                <AppBarSection>
                  <StyledUserIcon>
                    <Button variant="secondary" icon={AccountIcon}>
                      <span style={{ marginLeft: "8px" }}>{userName}</span>
                    </Button>
                  </StyledUserIcon>
                  <StyledAppBarSpacer style={{ width: 8 }} />
                  <Tooltip
                    openDelay={100}
                    position="bottom"
                    anchorElement="target"
                  >
                    <Button
                      aria-label="Logout"
                      onClick={handleLogout}
                      $rounded
                      title="Logout"
                      icon={UnionIcon}
                    />
                  </Tooltip>
                </AppBarSection>
              </DesktopOptions>
              <HamburgerWrapper>
                <AppBarSection>
                  <div ref={anchor}>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowOptions(!showOptions);
                      }}
                      icon={menuIcon}
                    ></Button>
                  </div>
                  {showOptions && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Popup anchor={anchor.current} show={showOptions}>
                        <div>
                          <StyledUl>
                            {NAVIGATION_ITEMS.filter(
                              (item) => !item.disabled
                            ).map((item) => (
                              <li key={item.path}>
                                <StyledLink
                                  to={item.path}
                                  onClick={() => setShowOptions(false)}
                                >
                                  {item.label}
                                </StyledLink>
                              </li>
                            ))}
                          </StyledUl>
                        </div>
                      </Popup>
                    </div>
                  )}
                </AppBarSection>
              </HamburgerWrapper>
              <StyledAppBarSpacer style={{ width: 20 }} />
              <StyledMobile>
                <Button
                  variant="secondary"
                  aria-label="AccountIcon"
                  icon={AccountIcon}
                  $rounded
                ></Button>
                <Tooltip
                  openDelay={100}
                  position="bottom"
                  anchorElement="target"
                >
                  <Button
                    aria-label="Logout"
                    onClick={handleLogout}
                    $rounded
                    title="Logout"
                    icon={UnionIcon}
                  />
                </Tooltip>
              </StyledMobile>
            </Nav>

            {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}

            {isTokenAvailable && (
              <ErrorBoundary>
                <ErrorContextProvider>
                  <Routes>
                    {ROUTES.filter((item) => !item.disabled).map((item) => (
                      <Route
                        key={item.path}
                        path={item.path}
                        element={item.component}
                      />
                    ))}
                  </Routes>
                </ErrorContextProvider>
              </ErrorBoundary>
            )}
          </Container>
        </Router>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <DialogBox isOpen={true}>
          <UnAuthenticatedModal
            handleLogin={() => msalInstance.loginRedirect(loginRequest)}
          />
        </DialogBox>
      </UnauthenticatedTemplate>
      <DialogBox isOpen={logoutConfirm}>
        <ConfirmModal
          headerText="Are you sure you want to Logout?"
          handleClose={handleCloseLogout}
          handleConfirm={handleConfirmLogout}
        />
      </DialogBox>
    </>
  );
}

export default App;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  @media ${layout.mobile}, ${layout.tablet} {
    order: 2;
  }
`;
const Container = styled.div`
  @media ${layout.mobile}, ${layout.tablet} {
    min-height: 99vh;
  }
`;

const Nav = styled(AppBar)`
  margin-bottom: 20px;

  @media ${layout.mobile}, ${layout.tablet} {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
`;
const StyledLink = styled(NavLink)`
  display: flex;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  gap: 20px;
  color: ${color.gray30};
  text-decoration: none;
  &:hover {
    background-color: ${color.gray100};
    border-radius: 32px;
  }
`;

const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  list-style-type: none;
  width: max-content;
`;

const Logo = styled.img`
  height: 47px;
  padding-left: 48px;
  @media ${layout.mobile}, ${layout.tablet} {
    height: 30px;
    padding-left: 20px;
  }
`;

const StyledUserIcon = styled.div`
  border: 1px solid #e5e5e3;
  border-radius: 50%;
`;

const StyledMobile = styled.div`
  display: none;
  @media ${layout.mobile}, ${layout.tablet} {
    display: flex;
    order: 3;
    gap: 8px;
  }
`;

const DesktopOptions = styled.div`
  display: block;
  font-size: 16px;
  @media ${layout.mobile}, ${layout.tablet} {
    display: none;
  }
`;

const HamburgerWrapper = styled.div`
  display: none;
  @media ${layout.mobile}, ${layout.tablet} {
    display: flex;
    order: 1;
  }
`;

const StyledAppBarSpacer = styled(AppBarSpacer)`
  @media ${layout.mobile}, ${layout.tablet} {
    display: none;
  }
`;

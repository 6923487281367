import { configureStore } from "@reduxjs/toolkit";
import { querySlice } from "./Slice/Api/QuerySlice";
import authReducer from "./Slice/Api/authSlice";
import updateRequestReducer from "./Slice/Api/updatedGridSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    updateRequest: updateRequestReducer,
    [querySlice.reducerPath]: querySlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(querySlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;

import { useContext } from "react";
import { ErrorContext } from "./ErrorContextProvider";

export interface ErrorContextType {
  errorMessage: string;
  setErrorMessage: (_val: string) => void;
}

export function useErrorContext() {
  const context = useContext(ErrorContext);
  if (context === undefined) {
    throw new Error("useErrorContext must be inside a Provider with a value");
  }
  return context;
}
import styled from "styled-components";
import Button from "../shared/Button/Button";
import { Typography } from "@progress/kendo-react-common";
import { layout } from "src/styles/_breakpoints";
import closeIcon from "src/images/closeIcon.svg";
import { Heading18 } from "src/styles/_typography";

interface ConfirmModalProps {
  headerText: string;
  handleClose: () => void;
  handleConfirm: () => void;
}

const ConfirmModal = ({
  headerText,
  handleClose,
  handleConfirm,
}: ConfirmModalProps) => {
  return (
    <ModalBody>
      <ModalClose>
        <CloseButton
          aria-label="Close"
          variant="secondary"
          $rounded
          icon={closeIcon}
          onClick={handleClose}
        />
      </ModalClose>
      <ModalHeader>{headerText} </ModalHeader>
      <ButtonContainer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </ButtonContainer>
    </ModalBody>
  );
};
export default ConfirmModal;

const ModalBody = styled.div`
  width: 450px;
  position: relative;
  padding: 15px 0;
  @media ${layout.mobile} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }
`;
const ModalHeader = styled(Typography.h2)`
  ${Heading18};
  margin: 16px;
  @media ${layout.mobile} {
    text-align: center;
  }
 }
`;
const ModalClose = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  @media ${layout.mobile} {
    top: 35px;
    right: 20px;
  }
`;
const CloseButton = styled(Button)`
  padding: 7px;
`;
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding-top: 15px;
  @media ${layout.mobile} {
    padding: 20px;
    > button {
      width: 50%;
    }
  }
`;
